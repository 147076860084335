export default class Calendar {
    constructor(id, name, description, notes, color, location, price,minBookingTime ) {
      this.id           = id;
      this.name         = name;
      this.description  = description;
      this.notes        = notes;
      this.color        = color;
      this.location     = location;
      this.price        = price;
      this.minBookingTime = minBookingTime
    }
  
    toUIJSON() {
      return {
        id          : this.id,
        name        : this.name,
        description : this.description,
        notes       : this.notes,
        color       : this.color,
        location    : this.location,
        price       : this.price,
        minBookingTime : this.minBookingTime
      };
    }
  
    toAPIJSON() {
      throw Error("Not implmeented yet"); //TODO: implement this
    }
  }
  