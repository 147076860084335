// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./svg/login-background.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    /* background-color: #41A3E8; */\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: center;\n}\n\n.login-form h1 {\n    margin-top: 40px;\n    font-family: 'Mark Pro Bold';\n    letter-spacing: 1.3px;\n    font-size: 80px;\n    color: white;\n}\n\n.login-form h3 {\n    height: 44px;\n    margin: 28px 0 4px 0;\n    font-family: 'Mark Pro Book';\n    letter-spacing: 0.5px;\n    font-size: 30px;\n    color: red;\n}\n\n.login-form .login-field {\n    margin-top: 50px;\n}", "",{"version":3,"sources":["webpack://src/styles/login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,+BAA+B;IAC/B,yDAAmD;IACnD,4BAA4B;IAC5B,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;IAChB,4BAA4B;IAC5B,qBAAqB;IACrB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,oBAAoB;IACpB,4BAA4B;IAC5B,qBAAqB;IACrB,eAAe;IACf,UAAU;AACd;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".login-form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    /* background-color: #41A3E8; */\n    background-image: url(\"./svg/login-background.svg\");\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: center;\n}\n\n.login-form h1 {\n    margin-top: 40px;\n    font-family: 'Mark Pro Bold';\n    letter-spacing: 1.3px;\n    font-size: 80px;\n    color: white;\n}\n\n.login-form h3 {\n    height: 44px;\n    margin: 28px 0 4px 0;\n    font-family: 'Mark Pro Book';\n    letter-spacing: 0.5px;\n    font-size: 30px;\n    color: red;\n}\n\n.login-form .login-field {\n    margin-top: 50px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
