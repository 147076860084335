import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/de';

export default  class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateTime: Timer.getDateAndTimeAsHumanReadable()
    };
  }

  static getDateAndTimeAsHumanReadable = () => {
    return moment().format("HH:mm");
 /*   let now = new Date();  let date = ('0' + now.getDate()).slice(-2) + '-'
      + ('0' + (now.getMonth() + 1)).slice(-2) + '-'
      + now.getFullYear();
    let time = ('0' + now.getHours()).slice(-2) + ':'
      + ('0' + now.getMinutes()).slice(-2);
    return { date: date, time: time }; */
  };

  componentDidMount() {
    if(this.props.lang === "en-US"){
      moment.locale("en")
    }else{
      moment.locale("de")
    }
    this.updateEventsInterval = setInterval(
      () => {
        this.setState({ dateTime: Timer.getDateAndTimeAsHumanReadable() });
      },
      1000
    );
  }

  componentWillUnmout() {
    clearInterval(this.updateEventsInterval);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.lang !== this.props.lang) {
      if(nextProps.lang === "en-US"){
        moment.locale("en")
      }else{
        moment.locale("de")
      }
    }
  }

  render() {
      return <div className="main-head">
                <div class="time-info">{this.state.dateTime}</div>
                <div class="date-info">{(moment().format("dddd, DD. MMMM"))}</div>
            </div>;
  }
}
