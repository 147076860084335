import React, { Component } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment';
import { converterForReactCalendar } from '../helpers/event';

const localizer = momentLocalizer(moment);
export default class DailySchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const { events } = this.props;
        console.log("Daily",events)
        let convertedEvents = converterForReactCalendar(events);
        return <Calendar events={convertedEvents}
            localizer={localizer}
            toolbar={false}
            style={{ height: '100%' }}
            min={new Date(0, 0, 0, 7, 0, 0)}
            max={new Date(0, 0, 0, 19, 0, 0)}
            localizer={localizer}
            defaultView={Views.DAY}
            views={["day", "work_week"]}
            defaultDate={new Date()}
        />
    }
};