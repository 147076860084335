import { Grid } from "@material-ui/core";
import { Component } from "react";

export default class Logo extends Component {
    constructor(props) {
        super(props);
        this.logoPath = '/images/logo.png';
    }
    render() {
        return (<Grid item style={{position:'absolute', left:'30px', top:'30px' }}>
        <div class="logo"><img src={this.logoPath} alt="" /></div>
        </Grid>)
    }
}