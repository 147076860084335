export default class Event {
    constructor(id, title, startDate, endDate, status, isAllDay, eventObj) {
      this.id           = id;
      this.title        = title;
      this.startDate    = startDate;
      this.endDate      = endDate;
      this.isAllDay     = isAllDay;
      this.status       = status;
      this.eventObj     = eventObj;
    }
  
    toUIJSON() {
      const snapshot = JSON.parse(this.eventObj.createdEventSnapshot)
      return {
        id           : this.id,
        title        : this.title,
        start        : this.startDate,
        end          : this.endDate,
        isAllDay     : this.isAllDay,
        color        : this.eventObj.color,
        status       : this.status,
        organiser    : this.eventObj.Account,
        customerName : this.eventObj.customerName,
        note         : this.eventObj.note, // TODO(Kaan): Should add welcome message for OZ
        welcomeMessage : snapshot.welcomeMessage
      };
    }
  
    toAPIJSON() {
      throw Error("Not implmeented yet"); //TODO: implement this
    }
  }
  