import React, { Component } from 'react';
import classNames from 'classnames';
import '../styles/button.css';

export default class Button extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clicked: false,
            disabled: false
        };
    }
    componentWillUnmount() {
        clearTimeout(this.clickedTimer);
    }

    handleClick = (e) => {
        this.setState({ clicked: true });
        this.clickedTimer = setTimeout(
            () => this.setState({ clicked: false }),
            1000
        );
        this.props.handleClick(e);
    };

    prepareStyle = () => {
        return {
            color: this.props.deactivated ? "#4d4d4d" : this.props.color ? this.props.color : "#FFFFFF",
            fontSize: this.props.fontSize ? this.props.fontSize : "24px"
        };
    };

    prepareStyleWrapper = () => {
        return {
            background: this.props.wrapperBackground ? this.props.wrapperBackground: null
        };
    };

    render() {
        const btnClasses = classNames({
            clicked: this.state.clicked,
            disabled: this.props.disabled
        }, this.props.className);
        return <button onClick={this.handleClick} style={this.prepareStyleWrapper()}  className={btnClasses} disabled={this.state.clicked || this.props.deactivated}>
            <div style={{ display: "inline-block" }}>
                <div style={this.prepareStyle()}>{this.props.name}</div>
            </div>
            {this.props.rightIcon ? <div style={{ width: 24, height: 24, display: "inline-block", float: "right", }}><i className="icon icon-menu"></i></div> : ""}
        </button>
    }
};