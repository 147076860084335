import React, { Component } from 'react';
import EventDuration from './EventDuration';
import BackendApi from '../backendcomm/api/app2backend';
import { processEvents } from '../helpers/event';
import { STATUS_UPDATE_INTERVAL_MS } from '../helpers/constants';
import { currentEvent, nextEvent, humanReadableDuration2, addMinutes } from '../helpers/util';
import { isCheckConnectionView } from '../helpers/location';
import DailySchedule from './DailySchedule';
import navigate from '../helpers/navigate';
import { AppContext } from '../middlewares/appContext/AppContext';
import { getEvents } from '../data/getEvents';
import { getCalendar } from '../data/getCalendar';
import Available from './Avaliable';
import Booked from './Booked';
import { withTranslation } from 'react-i18next';

class Status extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            detailsExpanded: false,
            schedulePageExpanded: false,
            events: [],
            currentEvent: {},
            nextEvent: {},
            waitUntil: "",
            meetingRoomDetails: {},
            tenantId: "",
            roomId: "",
            deviceId: "",
            organizerId: "",
            lang : (localStorage.getItem('language')) ? localStorage.getItem('language') : 'de-DE'
        }
        this.colors = {
            booked:'#e84f1c',
            available:'4f80ff'
        }
    }

    componentDidMount() {
        //No need to check isAuth control here, will be controlled from PrivateRoute
        this.setState({tenantId:this.context.device.tenant.tenant, deviceId: this.context.device.id ,roomId: this.context.device.room.id,organizerId : this.context.accountId},() => {

            this.fetchCalendarEvents();
            this.setUpdateDisplayedEventsInterval();
            this.fetchCalendarStatus();
        })
        const {t,i18n} = this.props
        i18n.changeLanguage(this.state.lang); localStorage.setItem('language',this.state.lang); 
    }

    componentWillUnmount() {
        clearInterval(this.updateEventsInterval);
    }

    failCbFetchEvents = (err) => {
        console.error("Error when getting events: ", err);
        if(err.logoutSuccess){
            navigate('/login?isAuthenticated=false');
        }else{
            navigate('/check_connection');
        }
    }

    successCbFetchEvents = (bookings) => {
        if (isCheckConnectionView()) {
            console.log("STATUS successFetchEvents status")
            navigate(`/status`);
        }
        console.log("successCbFetchEvents",bookings)
        let events = processEvents(bookings);
            this.setState({
                events:events,
                currentEvent: currentEvent(events),
                nextEvent: nextEvent(events),
            },()=>{
                console.log("After set State",this.state.events)
            });
    }

    fetchCalendarEvents = () => {
        getEvents(this.state.tenantId, this.context.accountId,this.successCbFetchEvents,this.failCbFetchEvents)
    };

    setUpdateDisplayedEventsInterval() {
        this.updateEventsInterval = setInterval(() => {
            this.fetchCalendarEvents();
        }, STATUS_UPDATE_INTERVAL_MS);
    };

    failCbCalendarStatus = (err) => {
        console.error(err);
        setTimeout(() => this.fetchCalendarStatus(), 10000);
    }
    successCbCalendarStatus = (calendar) => {
        this.setState({meetingRoomDetails:calendar});
    }
    fetchCalendarStatus = () => {
        getCalendar(this.state.tenantId,this.state.roomId,this.successCbCalendarStatus,this.failCbCalendarStatus);
    };

    addQuickReservation = (duration) => {
        const { t } = this.props
        const eventStartDate = new Date();
        const eventEndDate = addMinutes(eventStartDate,duration);
        const booking = {
            owner_id: this.state.organizerId,
            device_id: this.state.deviceId,
            startDate: eventStartDate.toString(),
            endDate: eventEndDate.toString(),
            title: `${t('Status.Title')} ${duration} ${t('Status.Min')}`,
        }
        BackendApi.addNewBooking(this.state.tenantId, this.context.accountId,booking, (res) => {
            this.fetchCalendarEvents();
        }, (err) => {
            this.fetchCalendarEvents();
            const now = new Date();
            let wait_until = new Date(now);
            wait_until.setMinutes(now.getMinutes() + 1);
            wait_until.setSeconds(0);
            this.waitUntil(wait_until);
        });
    };

    waitUntil = (date) => {
        let untilDate = new Date(date);
        this.intervalForWaitUntil = setInterval(() => {
            let now = new Date();
            if (untilDate < now) {
                clearInterval(this.intervalForWaitUntil);
                this.setState({ waitUntil: null });
            } else {
                this.setState({ waitUntil: humanReadableDuration2(untilDate - now) });
            }
        }, 1000);
    };

    finishReservetaion = (eventId) => {
        BackendApi.finishBooking(this.state.tenantId,eventId,{ endDate: new Date().toString() }, (res) => {
           this.fetchCalendarEvents();
        }, (err) => {
            console.error(err);
        });
    };

    handleShowSchedule = () => {
        // if need to change screen for events use this function.
        this.props.navigateSchedule(this.state.events);
    };

    handleExpandDetails = () => {
        this.setState({
            detailsExpanded: !this.state.detailsExpanded,
        });
    };

    handleChangeOrganizerId = (selectedOrganizerId) =>{
        this.setState({
            organizerId : selectedOrganizerId
        })
    }

    isBooked = () => {
        const { currentEvent } = this.state;
        const now = Date.now();
        return Object.keys(currentEvent).length > 0
            && Date.parse(currentEvent.start) <= now
            && Date.parse(currentEvent.end) > now;
    };

    toggleExpandScheduleScreen = () => {
        this.setState({ schedulePageExpanded: !this.state.schedulePageExpanded });
    };

    renderStatusComponent = (currentEvent, nextEvent, onFinishReservation, onQuickReservation, waitUntil) => {
        return this.isBooked() ?
            <Booked
                onClick={() => onFinishReservation(currentEvent.id)}
                currentEvent={currentEvent}
                key={1}
                waitUntil={waitUntil}
                onShowScheduleClick={this.toggleExpandScheduleScreen}
                room={this.state.meetingRoomDetails} 
                lang={this.state.lang}
                handleLanguageChange = {this.handleLanguageChange}
                /> :
            <Available
                onClick15={() => onQuickReservation(15)}
                onClick30={() => onQuickReservation(30)}
                onClick60={() => onQuickReservation(60)}
                nextEvent={nextEvent}
                key={1}
                handleChangeOrganizerId = {this.handleChangeOrganizerId}
                waitUntil={waitUntil}
                onShowScheduleClick={this.toggleExpandScheduleScreen}
                room={this.state.meetingRoomDetails} 
                lang={this.state.lang}
                handleLanguageChange = {this.handleLanguageChange}
                />;
    };

    renderEventList = (events) => {
        return events.map((event, index) => {
            return (
                <div key={`event_item_${index}`}>
                    <EventDuration event={event} />
                </div>);
        });
    };

    calculateScheduleStyle = (condition) => {
        return condition ? { display: "inline", width: "30%" } : { display: "none", width: "0%" };
    };

    handleLanguageChange = (event) => {
        const targetLang = event.target.value
        this.setState({lang : targetLang})
        const {i18n} = this.props
        i18n.changeLanguage(targetLang); localStorage.setItem('language',targetLang); 
      };

    render() {
        const { t } = this.props
        const { events, nextEvent, currentEvent, waitUntil } = this.state;
        return <div>
            <div style={{ display: "inline", width: this.state.schedulePageExpanded ? "70%" : "100%", float: "left" }}>
                <div>
                    {this.renderStatusComponent(currentEvent, nextEvent, this.finishReservetaion, this.addQuickReservation, waitUntil)}
                </div>
            </div>
            <div className="width-change-animation" style={this.calculateScheduleStyle(this.state.schedulePageExpanded)}>
                <div style={{ height: '91vh', marginBottom: "5vh" }}>
                    <h3 style={{ color: this.isBooked() ? this.colors.booked : this.colors.available }}>{t('Status.FullSchedule')}</h3>
                    <div style={{ overflow: "auto", maxHeight: "100vh", marginRight: "-12px", height: '100%' }}>
                        {/* this.renderEventList(events) */}
                        <DailySchedule events={events} />
                    </div>
                </div>
            </div>
        </div >;
    }
};

export default withTranslation()(Status)