import Event from "../models/Event";
import BackendApi from '../backendcomm/api/app2backend';

export function getEvents(tenantId, deviceAccountId ,successCb,errorCb) {
    let events = [];
    BackendApi.getBookings(tenantId, deviceAccountId, (res) => {
        res.forEach(event => {
            const newEvent = new Event(event.id, event.title, event.start, event.end, event.eventObj.status, event.eventObj.isAllDay, event.eventObj).toUIJSON();
            events.push(newEvent);
        });
        successCb(events);
    }, (err) => {
        errorCb(err);
    })
}