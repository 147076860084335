import humanizeDuration from 'humanize-duration';
import moment from 'moment';

export const nextEvent = (events) => {
  const now = new Date().getTime();

  const sortedEvents = events.sort(function(a, b) {
    return new Date(a.start).getTime() - new Date(b.start).getTime();
  });

  const futureEvents = sortedEvents.filter(function(event) {
    return new Date(event.start).getTime() > now;
  });

  return futureEvents[0] || {};
};

export const currentEvent = (events) => {
  const now = new Date().getTime();

  const currentEvents = events.filter(function(event) {
    
    const eventStart = new Date(event.start).getTime();
    const eventEnd = new Date(event.end).getTime();

    return (eventStart <= now && eventEnd >= now);
  });

  return currentEvents[0] || {};
};

export const timeToEvent = (event) => {
  return (Date.parse(event.start) - Date.now());
};

export const timeLeft = (event) => {
  return Date.parse(event.end) - Date.now();
};

export const humanReadableDuration = (ms) => {
  // largest: max number of units to display, round: round to smallest unit displayed
  return humanizeDuration(ms, { largest: 1, round: true, units: ['d', 'h', 'm'] });
};

export const humanReadableDuration2 = (ms) => {
  // largest: max number of units to display, round: round to smallest unit displayed
  return humanizeDuration(ms, { largest: 1, round: true, units: ['d', 'h', 'm','s'] });
};

export const isCurrent = (event) => {
  return timeToEvent(event) <= 0 && timeLeft(event) >= 0;
};

export const isBeforeNow = (event) => {
  return timeLeft(event) < 0;
};

export const isAfterNow = (event) => {
  return timeToEvent(event) > 0;
};

export const isAllDayEvent = (event) => {
  return event.isAllDay;
};

export const addMinutes = (date, minutes) => {
  return new Date(date.getTime() + minutes*60000);
}

export const checkMinBookingTime = (minBookingTime,minute) => {
  let start = new Date ();
  let end = moment(start).add(minute,'m')
  const maxHour =  new Date(start);
  maxHour.setHours(23,45,0);  moment(maxHour).format("HH:mm")
  const minHour = new Date(end);
  minHour.setHours(0,0,0);  moment(minHour).format("HH:mm")
  let isValid = true;
  if(moment(start).isSameOrAfter(moment(maxHour).subtract(minBookingTime,'m'))){ //
    isValid = false
  }else if(moment(end).isSameOrBefore(moment(minHour).add(minBookingTime,'m'))){
    isValid = false
  }else if (minBookingTime > (moment(end).diff(moment(start),'minutes'))){  // ++++
    isValid = false
  }
  return isValid
}