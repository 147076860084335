import React from 'react';
import { Router, Switch } from 'react-router-dom';
import './styles/main.css';
import history from './helpers/history';
import UserLogin from './components/UserAuth/UserLogin';
import { PublicRoute } from './middlewares/routes/PublicRoute';
import BackendApi from './backendcomm/api/app2backend';
import { PrivateRoute } from './middlewares/routes/PrivateRoute';
import CheckConnection from './components/CheckConnection';
import Schedule from './components/Schedule';
import Status from './components/Status';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      events: [],
      meetingRoomDetails: {},
      authenticatedDeviceData: null,
      loading: true
  };
  }

  componentDidMount() {
    BackendApi.auth((data) => {
        this.setState({ isAuthenticated: true, authenticatedDeviceData:data, loading:false });
    }, (err) => {
        console.log("Error: " + err);
        if(err.invalidToken){
          this.setState({ isAuthenticated: true, authenticatedDeviceData:err.data, loading:false });
        }else{
          if(err.logoutSuccess){
            this.setState({loading:false})
          }else{
            this.setState({loading:false})
          }
        }
    });
  }

  handleChangeIsAuth (value){
    this.setState({isAuthenticated: value,})
  }

  render() {
      return (
        <div className="App">
          {
            !this.state.loading ? 
              <Router history={history}>
                <Switch>
                    <PublicRoute path={"/login"}  handleChangeIsAuth={this.handleChangeIsAuth} isAuthenticated={this.state.isAuthenticated} Component={UserLogin}/>
                    <PrivateRoute path={"/status"}  isAuthenticated={this.state.isAuthenticated} authenticatedDeviceData={this.state.authenticatedDeviceData} Component={Status}/>
                    <PrivateRoute path={"/check_connection"}  isAuthenticated={this.state.isAuthenticated} authenticatedDeviceData={this.state.authenticatedDeviceData} Component={CheckConnection}/>
                    <PrivateRoute path={"/schedule"}  isAuthenticated={this.state.isAuthenticated} authenticatedDeviceData={this.state.authenticatedDeviceData} Component={Schedule}/>
                    <PublicRoute path={"/"}  handleChangeIsAuth={this.handleChangeIsAuth} isAuthenticated={this.state.isAuthenticated} Component={UserLogin}/>
                </Switch>        
              </Router> 
              :null
          }
        </div>
      );
    }
};

export default App;