import axios from "axios";
import navigate from '../../helpers/navigate';
import BackendApi from '../../backendcomm/api/app2backend';
export default class HTTPClient {

    static _notifierFunc;

    constructor() {
        this._params = {};
        this._timeout = 10000;
        this._contentType = HTTPClient.CONTENT_TYPE.JSON;
        this._responseType = HTTPClient.RESPONSE_TYPE.JSON;
        this._acceptType = HTTPClient.ACCEPT.DEFAULT;
        this._formData = null;
        this._userNotification = null;
    }

    set requestPath(path) {
        this._reqPath = path;
    }

    set requestType(type) {
        if (Object.keys(HTTPClient.REQUEST_TYPE).includes(type)) {
            this._reqType = type;
        } else {
            console.error("Request type is not defined on HTTPClient")
        }
    }

    set successCallback(cb) {
        this._successCB = cb;
    }

    set failCallback(cb) {
        this._failCB = cb;
    }

    set timeout(duration) {
        this._timeout = duration;
    }

    set contentType(type) {
        this._contentType = type;
    }

    set responseType(type) {
        this._responseType = type;
    }

    set acceptType(type) {
        this._acceptType = type;
    }

    set formData(formData) {
        this._formData = formData;
    }

    set userNotification(notification) {
        this._userNotification = notification;
    }

    addParameters = (params) => {
        this._params = { ...this._params, ...params };
    };

    static setAuthToken = (token) => {
        //axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    };

    static unsetAuthToken = () => {
        //axios.defaults.headers.common['Authorization'] = null;
    };

    _build = () => {
        const headers = { 'Content-Type': this._contentType, 'Accept': this._acceptType};
        const params = this._formData ? this._formData : this._params;
        this._promise = null;
        switch (this._reqType) {
            case HTTPClient.REQUEST_TYPE.GET:
                this._promise = axios.get(this._reqPath, { headers: headers, timeout: this._timeout, responseType: this._responseType, withCredentials:true });
                break;
            case HTTPClient.REQUEST_TYPE.POST:
                this._promise = axios.post(this._reqPath, params, { headers: headers, timeout: this._timeout, responseType: this._responseType, withCredentials:true  });
                break;
            case HTTPClient.REQUEST_TYPE.PUT:
                this._promise = axios.put(this._reqPath, params, { headers: headers, timeout: this._timeout, responseType: this._responseType, withCredentials:true  });
                break;
            case HTTPClient.REQUEST_TYPE.DELETE:
                this._promise = axios.delete(this._reqPath, { headers: headers, timeout: this._timeout, responseType: this._responseType, withCredentials:true  });
                break;
            default:
                console.log(`This request type (${this._reqType}) not handled by HTTPClient`);
                break;
        }
    };

    send = () => {
        this._build();
        this._promise.then((res) => {
            this._successCB(res.data);
            if (HTTPClient._notifierFunc && this._userNotification) {
                HTTPClient._notifierFunc(this._userNotification.title, {
                    variant: this._userNotification.type
                });
            }
        }).catch((err) => {
            if (err.response) {
                // Request made and server responded
                if(err.response.status === 401){
                    console.log("***err.response : ",err.response)
                    if(err.response.data.err && err.response.data.err === "Invalid Token"){
                        BackendApi.refresh(
                            data => {
                                if(err.response.config.url && err.response.config.url === `${process.env.REACT_APP_USERS_API_BASE_URL}/api/v1/devices/me`){
                                    err.response.invalidToken = true
                                    err.response.data = data
                                    this._failCB(err.response);
                                }else{
                                    window.location.reload() 
                                }
                            },
                            err => {

                            }
                        )                    
                    }else{
                        BackendApi.logout(
                            data => {
                                let language = localStorage.getItem('language');
                                localStorage.clear();
                                localStorage.setItem('language' , language)
                                err.response.logoutSuccess = true
                                this._failCB(err.response);
                              },
                              err => {
                                console.log("Error: " + err);
                              }
                        )
                    }
                }else{
                    this._failCB(err.response);
                }
              } else if (err.request) {
                // The request was made but no response was received
                this._failCB(err);
              } else {
                // Something happened in setting up the request that triggered an Error
                this._failCB(err);
              }
            if (HTTPClient._notifierFunc) {
                HTTPClient._notifierFunc("Operation failed", {
                    variant: 'error'
                });
            }
            //HTTPClient.showErrorFunction(err.toString());
        })
    };

}

HTTPClient.REQUEST_TYPE = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE"
};

HTTPClient.CONTENT_TYPE = {
    JSON: "application/json",
    MULTIPART: "multipart/form-data"
};

HTTPClient.RESPONSE_TYPE = {
    JSON: "json",
    BLOB: "blob"
};

HTTPClient.ACCEPT = {
    DEFAULT: "application/json, text/plain, */*",
    JSON: "application/json,*/*",
};