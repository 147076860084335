import React, { Component } from 'react';
import '../styles/noconnection.css';
import BackendApi from '../backendcomm/api/app2backend';
import navigate from '../helpers/navigate';
import { STATUS_UPDATE_INTERVAL_MS } from '../helpers/constants';
import { AppContext } from '../middlewares/appContext/AppContext';
import { getEvents } from '../data/getEvents';
import { withTranslation } from 'react-i18next';
class CheckConnection extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.setUpdateDisplayedEventsInterval();
    }

    componentWillUnmount() {
        clearInterval(this.updateEventsInterval);
    }

    setUpdateDisplayedEventsInterval() {
        this.updateEventsInterval = setInterval(() => {
            this.fetchCalendarEvents();
        }, STATUS_UPDATE_INTERVAL_MS);
    };

    successCbFetchEvents = () => {
        navigate(`/status`);
    }
    failCbFetchEvents = (err) => {
        console.error("Error when getting events: ", err);
    }

    fetchCalendarEvents = () => {
       getEvents(this.context.device.tenant.tenant,this.context.accountId, this.successCbFetchEvents, this.failCbFetchEvents)
    };

    render() {
        const { t } = this.props
        const { name } = this.context.device.room;
        return (
            <div className="no-connection">
                <h3 className="text">
                    {name}
                </h3>
                <div className="icon">
                    <i className="icon icon-no-connection"></i>
                </div>
                <h3 className="text">
                    {t('CheckConnection.ConnectionFail')}
                </h3>
            </div>
        );
    }
}
export default withTranslation()(CheckConnection)