import moment from "moment";

export const processEvents = (events) => {
    events = markAllDayEvents(events);
    events = removeUnconfirmedEvents(events);
    return events;
};

export const markAllDayEvents = (events) => {
    return events.map((event) => {
        if (event.isAllDay) {
            const start = new Date(event.start);
            start.setHours(0);
            const end = new Date(event.end);
            end.setHours(0);
            return {
                ...event,
                start: start,
                end: end,
            }
        } else {
            return {
                ...event
            }
        }
    });
};

export const removeUnconfirmedEvents = (events) => {
    return events.filter(event => {
        return event.status === 'booked' | 'reserved';
    });
};

export const converterForReactCalendar = (events) => {
    let convertedEvents = [];
    if (events) {
        events.map((event) => {
            if(moment(new Date()).isBefore(new Date(event.start),'minute')){
                let convertedEvent = {
                    id: event.id,
                    title: event.title || event.summary,
                    start: new Date(event.start),
                    end: new Date(event.end),
                    resourceId: event.calendarId
                }
                convertedEvents.push(convertedEvent);
            } 
        });
    }
    return convertedEvents;
};