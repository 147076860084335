import React, { Component } from 'react';
import { humanReadableDuration, timeToEvent ,checkMinBookingTime} from '../helpers/util';
import { isEmpty } from 'lodash/lang';
import { MILLISECONDS_PER_MINUTE } from '../helpers/constants';
import '../styles/available.css';
import '../styles/wait.css';
import Grid from "@material-ui/core/Grid";
import {ReactComponent as CalendarIcon} from '../styles/svg/calendar.svg';
import Logo from './Logo';
import Button from './Button';
import RoomDetails from './RoomDetails';
import Timer from './Timer';
import { Dialog, DialogContent, DialogTitle, FormControl, InputLabel, Select } from '@material-ui/core';
import BackendApi from '../backendcomm/api/app2backend';
import { AppContext } from '../middlewares/appContext/AppContext';
import { withTranslation } from 'react-i18next';
import LanguageDropdown from './languageDropDown'
class Available extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            openOrganizer:false,
            organizer:'',
            organizers:[],
            selectedOrganizer:'',
            tenantId:''
        };
    }
    componentDidMount = () => {
        this.setState({tenantId:this.context.device.tenant.tenant},() => {
            this.fetchOrganizers();
        })
    }
    freeStatusSubMessage = (nextEvent) => {
        const { t } = this.props
        const remainingTime = humanReadableDuration(timeToEvent(nextEvent));
        let message = ""
        if (parseInt(remainingTime, 10) < 1) {
            message = t('Avaliable.LastSecond')
            return message;
        }
        message = t('Avaliable.ForNext')
        return `${message} ${remainingTime}`;
    };

    lessThan15MinutesToEvent = (event) => {
        if(this.state.selectedOrganizer && checkMinBookingTime(this.props.room.minBookingTime,15)){
            return (!isEmpty(event) && timeToEvent(event) < 15 * MILLISECONDS_PER_MINUTE);
        }else{
            return true
        }
    };

    lessThan30MinutesToEvent = (event) => {
        if(this.state.selectedOrganizer && checkMinBookingTime(this.props.room.minBookingTime,30)){
            return (!isEmpty(event) && timeToEvent(event) < 30 * MILLISECONDS_PER_MINUTE);
        }else{
            return true
        }
    };

    lessThan60MinutesToEvent = (event) => {
        if(this.state.selectedOrganizer && checkMinBookingTime(this.props.room.minBookingTime,60) ){
            return (!isEmpty(event) && timeToEvent(event) < 60 * MILLISECONDS_PER_MINUTE);
        }else{
            return true
        }
    };

    renderAvailableInfo = (waitUntil, remainingTimeMessage) => {
        const { t } = this.props
        return <div className='status-bar-available' style={{ width: "100%"/* , height: "150px" */, marginTop: "90px" }}>
            <table><tbody>
                <tr><td>
                    <div style={{ display: "inline-block", }}>
                        {waitUntil ? <h2>{`${t('Avaliable.WillBeAvailable')} ${waitUntil}`}</h2> : <h1>{t('Avaliable.Available')}</h1>}
                    </div>
                </td></tr>
                <tr><td>
                    <div style={{ display: "block" }}>
                        <h3>{remainingTimeMessage}</h3>
                    </div>
                </td></tr>
            </tbody></table>
        </div>;
    };
    renderAvailableInfoNew = (waitUntil, remainingTimeMessage) => {
        const { t } = this.props
        return <div class="avaliable-info">
                    {waitUntil ? `${t('Avaliable.WillBeAvailable')} ${waitUntil}` : t('Avaliable.Available')}
                    <div style={{ display: "block" }}>
                        {remainingTimeMessage}
                    </div>
              </div>;
    }



    renderButtonGroupNew = (nextEvent, onClick15, onClick30, onClick60) => {
        const { t } = this.props
        return <Grid item container
        justifyContent="center"
        alignItems="center"
        className="button-group">
            <Grid item className='button-item'>
                <Button 
                name={t('Avaliable.15Min')}
                handleClick={onClick15}
                className={"button-primary"}
                deactivated={this.lessThan15MinutesToEvent(nextEvent)} />
            </Grid>
            <Grid item className='button-item'>
                <Button 
                name={t('Avaliable.30Min')}
                handleClick={onClick30}
                className={"button-primary"}
                deactivated={this.lessThan30MinutesToEvent(nextEvent)} />
            </Grid>
            <Grid item className='button-item'>
                <Button 
                name={t('Avaliable.60Min')}
                handleClick={onClick60}
                className={"button-primary"}
                deactivated={this.lessThan60MinutesToEvent(nextEvent)} />
            </Grid>
        </Grid>;
    };

    handleClickOpen = () => {
        console.log('handleClickOpen');
        this.setState({openOrganizer:true})
    }

    handleClickClose = () => {
        this.setState({openOrganizer:false})
    }

    handleOrganizerChanged = (event) => {
        let selectedOrganizerId = this.context.accountId
        if(event.target.value){
            let selectedOrganizer = this.state.organizers.filter(item => item.id == event.target.value)[0]
            this.setState({
                organizer:event.target.value, 
                openOrganizer:false,
                selectedOrganizer: selectedOrganizer?.name 
            });
            selectedOrganizerId = selectedOrganizer.id
        }else{
            this.setState({
                organizer:selectedOrganizerId,
                openOrganizer:false,
                selectedOrganizer: ""
                });
        }
        this.props.handleChangeOrganizerId(selectedOrganizerId)
    }

    fetchOrganizers = () => {
        BackendApi.getOrganizerList(this.state.tenantId, (res) => {
            console.log('res', res)
            const activeUsers = res.users.filter(oneUser => oneUser.status === "active")
            this.setState({ organizers: activeUsers },()=>{
                console.log("organizers",res.users)
                console.log("Active organizers",activeUsers)
            });  
        }, (err) => {
            console.error(err);
            // setTimeout(() => this.fetchCalendarStatus(), 10000);
        });
    }
    render() {
        const { t } = this.props
        const { nextEvent, onClick15, onClick30, onClick60, waitUntil, onShowScheduleClick, room } = this.props;
        const remainingTimeMessage = isEmpty(nextEvent) ? null : this.freeStatusSubMessage(nextEvent);
        const availableClassName = waitUntil ? 'wait-details' : 'status-details';
        return <div className={availableClassName} style={{background: 'url("/images/bg.png") no-repeat center center fixed'}}>
            <Grid container style={{height:'100vh'}}>
                <Logo />
                <Grid item xs={6}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className='left-side'
                >
                    <div className="main-head">
                        <RoomDetails roomObject={room} />
                        {this.renderAvailableInfoNew(waitUntil, remainingTimeMessage)}
                    </div>
                </Grid>
                <Grid item xs={6}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className="right-side">
                <Grid item style={{position:'absolute', right:'30px', top:'30px' }}> 
                    <FormControl>
                        <LanguageDropdown lang={this.props.lang} onChange={this.props.handleLanguageChange} />
                    </FormControl>
                </Grid>                     
                <Timer lang={this.props.lang}/>
                    {this.renderButtonGroupNew(nextEvent, onClick15, onClick30, onClick60)}
                    <Grid item container direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className="button-group">
                        <Grid item className='button-item'>
                            <Button 
                            name={(this.state.selectedOrganizer) ? `${t('Avaliable.Organizer_')}  ${this.state.selectedOrganizer}` : t('Avaliable.Organizer') } 
                            click={this.handleClickOpen}
                            className={"button-primary"} 
                            handleClick={this.handleClickOpen}
                            />
                           
                        </Grid>
                    </Grid>
                    <Dialog onClose={this.handleClickClose} open={this.state.openOrganizer}>
                        <DialogTitle>{t('Avaliable.SelectOrganizer')}</DialogTitle>
                        <DialogContent>
                        <FormControl style={{width:400,height:400}}>
                            <InputLabel htmlFor="organizer">{t('Avaliable.Organizer')}</InputLabel>
                            <Select
                                native 
                                style={{minwidth: 300}}
                                value={this.state.organizer}
                                onChange={this.handleOrganizerChanged}>
                                <option aria-label="None" value="" />
                                {this.state.organizers.map( (item) => {
                                    return <option value={item.id}>{item.name}</option>
                                })}
                            </Select>
                        </FormControl>
                        </DialogContent>
                    </Dialog>
                    <Grid item container direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className="button-group">
                        <Grid item className='button-item'>
                            <button className="icon-button" onClick={onShowScheduleClick}>
                                <CalendarIcon height="80%" />
                            </button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
{/*             <HeaderBar onClick={onShowScheduleClick} />
            <RoomDetails roomObject={room} />
            {this.renderAvailableInfo(waitUntil, remainingTimeMessage)}
            <div style={{ marginTop: "40px" }} className='quick-booking'>
                <h3>{"Quick Booking"}</h3>
            </div>
            {this.renderButtonGroup(nextEvent, onClick15, onClick30, onClick60)} */}
        </div>;
    }
};

export default withTranslation()(Available)