import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom';
import Device from '../../models/Device';
import { AppContext } from '../appContext/AppContext';

export const PrivateRoute = (props) => {

    const {Component, isAuthenticated, authenticatedDeviceData} = props;
    
    console.log("pricateRoute status isAuth: ",isAuthenticated)
    if (!isAuthenticated ) {
        return <Redirect to="/login"/>
    }

    const device = new Device(
        authenticatedDeviceData.deviceId, 
        authenticatedDeviceData.email, 
        authenticatedDeviceData.deviceName, 
        authenticatedDeviceData.deviceRoom, 
        authenticatedDeviceData.tenants[0] 
    );

    if (!device.room) {
        return <Redirect to="/check_connection" />
    }

    const contextValue = {
        device: device,
        accountId: authenticatedDeviceData._id,
        language: 'en-US' // TODO(Kaan): Add localization
    } 

    return <AppContext.Provider value={contextValue}>
        <Component/>
    </AppContext.Provider>;
    
}

PrivateRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    authenticatedDeviceData: PropTypes.object.isRequired,
    Component: PropTypes.node.isRequired,
    path: PropTypes.string.isRequired,
    exact: PropTypes.bool
}

