import React, { Component } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash/lang';
import { isAllDayEvent } from '../helpers/util';
import { withTranslation } from 'react-i18next';
class EventDuration extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const { t } = this.props
        const { event } = this.props;
        const startTime = moment(event.start);
        const endTime = moment(event.end);
        if (isEmpty(event)) {
            return null;
        }
        const isAllDay = isAllDayEvent(event);
        return <div style={{ marginBottom: "10px", paddingLeft: "15px" }}>
            <div style={{ fontSize: "20px", textAlign: "left" }}>
                {isAllDay ?
                    t('EventDuration.AllDay') :
                    `${startTime.format("H:mm")} - ${endTime.format("H:mm")}`
                }
            </div>
            <div style={{ fontSize: "15px", textAlign: "left" }}>
                {event.title || event.summary}
            </div>
        </div>;
    }
};
export default withTranslation()(EventDuration)