export default class Device {
    constructor(id, email, name, room, tenant) {
      this.id         = id;
      this.name       = name;
      this.email      = email;
      this.room       = room;
      this.tenant     = tenant
    }
  
    toUIJSON() {
      return {
        id          : this.id,
        name        : this.name,
        email       : this.email,
        room        : this.room,
        tenant      : this.tenant
      };
    }
  
    toAPIJSON() {
      throw Error("Not implmeented yet"); //TODO: implement this
    }
  }
  