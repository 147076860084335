import React, { Component } from 'react';
import { isBeforeNow, isAfterNow, isCurrent, timeLeft } from '../helpers/util';
import moment from 'moment';
import EventDuration from './EventDuration';
import '../styles/schedule.css';

export default class Schedule extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        if (this.refs.timeLinePosition) {
            this.scrollTimeLineIntoView();
        }
    }

    componentDidUpdate() {
        if (this.refs.timeLinePosition) {
            this.scrollTimeLineIntoView();
        }
    }

    scrollTimeLineIntoView() {
        this.refs.timeLinePosition.scrollIntoView({ behavior: 'instant' });
    };

    timeLine() {
        return (
            <span className="time-line"></span>
        );
    };

    scrollToTimeLine(container) {
        if (container === null) {
            return;
        }
        container.scrollIntoView({ behavior: 'instant' });
    };

    renderDisplayEvents = (events) => {
        const displayedEvents = events.map((event, index) => {
            let eventBefore = index > 0 ? events[index - 1] : null;
            let isBefore = eventBefore ? isBeforeNow(eventBefore) && isAfterNow(event) : isAfterNow(event);
            return (
                <div className="flex-container schedule-event" key={index}>
                    {isBefore ? <span ref="timeLinePosition"></span> : null}
                    {isBefore ? this.timeLine() : null}
                    <EventDuration event={event} />
                    {(isCurrent(event)) ? this.timeLine() : null}
                    <h3 className="schedule-event-name">{event.title || event.summary}</h3>
                </div>
            );
        });
        // Special case where the time line is located after all events
        if (events.length > 0 && timeLeft(events[events.length - 1]) < 0) {
            displayedEvents.push((
                <div className="flex-container schedule-event" key={events.length}>
                    <span ref="timeLinePosition"></span>
                    <h3 className="schedule-event-name"></h3>
                    {this.timeLine()}
                </div>
            ));
        }
        return displayedEvents;
    };

    render() {
        const { events } = this.props;
        return <div className="flex-container schedule">
            <h3 className="schedule-header">{(moment().format("dddd, DD.MM.YYYY")).toUpperCase()}</h3>
            <div className="schedule-event-list">
                <div className="flex-container">
                    {this.renderDisplayEvents(events)}
                </div>
            </div>
        </div>;
    }
};