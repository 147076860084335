function currentHash() {
    return window.location.pathname;
}

export function isStatusView() {
    return /status/.test(currentHash());
}

export const isCheckConnectionView = () => {
    return /check_connection/.test(currentHash());
};