// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./svg/no-connection.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".no-connection {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    background-color: #F60755;\n}\n\n.icon {\n    display: inline-block;\n    width: 20vw;\n    height: 40vh;\n    background-repeat: no-repeat;\n    background-position: center center;\n    background-size: 100%;\n}\n\n.icon-no-connection {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    color: white;\n}\n\nh3.text {\n    color: white;\n    text-align: center;\n    width: 80vw;\n}", "",{"version":3,"sources":["webpack://src/styles/noconnection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,4BAA4B;IAC5B,kCAAkC;IAClC,qBAAqB;AACzB;;AAEA;IACI,yDAAgD;IAChD,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,WAAW;AACf","sourcesContent":[".no-connection {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    background-color: #F60755;\n}\n\n.icon {\n    display: inline-block;\n    width: 20vw;\n    height: 40vh;\n    background-repeat: no-repeat;\n    background-position: center center;\n    background-size: 100%;\n}\n\n.icon-no-connection {\n    background-image: url('./svg/no-connection.svg');\n    color: white;\n}\n\nh3.text {\n    color: white;\n    text-align: center;\n    width: 80vw;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
