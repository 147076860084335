import HTTPClient from "../http_client/HttpClient";
import { REQUEST_PATHS_FOR } from "../http_client/RequestBuilder";

export const HTTP_REQUESTS = {
    USER_SERVICE: {
        LOGIN: (loginCredentials, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.USERS_SERVICE.LOGIN();
            client.requestType = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 5000; // 5 seconds
            client.addParameters(loginCredentials);
            client.send();
        },
        AUTH_CALL: (successCallback,errorCallback)=>{
            let client              = new HTTPClient();
            client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.AUTH_CALL();
            client.requestType      = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback  = successCallback;
            client.failCallback     = errorCallback;
            client.timeout          = 5000; // 5 seconds
            //client.addParameters(loginCredentials);
            client.send();
        },
        ORGANIZER_LIST: (tenantId, successCallback, errorCallback) => {
            let client              = new HTTPClient();
            client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.ORGANIZER_LIST(tenantId);
            client.requestType      = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback  = successCallback;
            client.failCallback     = errorCallback;
            client.timeout          = 5000; // 5 seconds
            //client.addParameters(loginCredentials);
            client.send();
         },
         REFRESH: (successCallback,errorCallback)=>{
            let client              = new HTTPClient();
            client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.REFRESH();
            client.requestType      = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback  = successCallback;
            client.failCallback     = errorCallback;
            client.timeout          = 5000; // 5 seconds
            //client.addParameters(loginCredentials);
            client.send();
        },
        LOGOUT: (successCallback,errorCallback)=>{
            let client              = new HTTPClient();
            client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.LOGOUT();
            client.requestType      = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback  = successCallback;
            client.failCallback     = errorCallback;
            client.timeout          = 5000; // 5 seconds
            client.send();
        },
    },
    MOBILE_SERVICE: {
        GET_CALENDAR: (tenantId,roomId,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.MOBILE_SERVICE.GET_CALENDAR(tenantId,roomId);
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec 
            client.send();
        },
        GET_BOOKINGS: (tenantId,deviceAccountId,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.MOBILE_SERVICE.GET_BOOKINGS(tenantId,deviceAccountId);
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec 
            client.send();
        },
        ADD_NEW_BOOKING: (tenantId,deviceId,eventInfo, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.MOBILE_SERVICE.ADD_NEW_BOOKING(tenantId,deviceId);
            client.requestType = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.addParameters(eventInfo);
            client.send();
        },
        FINISH_BOOKING: (tenantId,eventId,eventInfo, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.MOBILE_SERVICE.FINISH_BOOKING(tenantId,eventId);
            client.requestType = HTTPClient.REQUEST_TYPE.PUT;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.addParameters(eventInfo);
            client.send();
        },
    }
};