import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom';

export const PublicRoute = (props) => {

    const {Component, isAuthenticated} = props;
    var url = new URL(window.location.href);
    var isAuthenticatedFromUrl = url.searchParams.get("isAuthenticated");
    if (isAuthenticated && isAuthenticatedFromUrl !== "false") {
        return <Redirect to="/status"/>
    }else{
        return <Component/>;
    }


    
}

PublicRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    Component: PropTypes.node.isRequired,
    path: PropTypes.string.isRequired,
    exact: PropTypes.bool
}

