import React, { Component } from 'react';
import { humanReadableDuration, timeLeft, isAllDayEvent } from '../helpers/util';
import { isEmpty } from 'lodash/lang';
import EventDurationBooked from './EventDurationBooked';
import '../styles/booked.css';
import '../styles/wait.css';
import Logo from './Logo';
import { FormControl, Grid } from '@material-ui/core';
import RoomDetails from './RoomDetails';
import Timer from './Timer';
import Button from './Button';
import {ReactComponent as CalendarIcon} from '../styles/svg/calendar.svg';
import { withTranslation } from 'react-i18next';
import LanguageDropdown from './languageDropDown'
class Booked extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    bookedStatusSubMessage = (currentEvent) => {
        const { t } = this.props
        const remainingTime = humanReadableDuration(timeLeft(currentEvent));
        let message = ""
        if (parseInt(remainingTime, 10) < 1) {
            message = t('Booked.LastSecond')
            return message;
        }
        message = t('Booked.ForNext')
        return `${message} ${remainingTime}`;
    };

    conditionalFinishButton = (currEventMemId, onClick, condition) => {
        const { t } = this.props
        return condition ? <Button name={t('Booked.EndNow')} handleClick={onClick} className={"end-now-button"} wrapperBackground={'#FE5900'} color={'#f8f9fa'} /> : null;
    };

    renderBookedInfo = (waitUntil, remainingTimeMessage) => {
        const { t } = this.props
        return <div className='status-bar-booked' style={{ width: "100%"/* , height: "150px" */, marginTop: "90px" }}>
            <table><tbody>
                <tr><td>
                    <div style={{ display: "inline-block" }}>
                        {waitUntil ? <h2>{`${t('Booked.WillBeAvailable')} ${waitUntil}`}</h2> : <h1>{t('Booked.Booked')}</h1>}
                    </div>
                </td></tr>
                <tr><td>
                    <div style={{ display: "block" }}>
                        <h3>{remainingTimeMessage}</h3>
                    </div>
                </td></tr>
            </tbody></table>
        </div>;
    };

    renderBookedInfoNew = (waitUntil, remainingTimeMessage) => {
        const { t } = this.props
        return <div className='avaliable-info'>
                    <div style={{ display: "block" }}>
                        {waitUntil ? `${t('Booked.WillBeAvailable')} ${waitUntil}` : t('Booked.Booked')} {remainingTimeMessage}
                    </div>
                </div>;
    };

    renderCurrentInfo = (currentEvent, endNowButton) => {
        const { t } = this.props
        return <div>
            <div style={{ marginTop: "20px" }} className='current-meeting'>
                <h3>{ t('Booked.CurrentMeeting')}</h3>
            </div>
            <div style={{ fontSize: "20px" }}>
                {currentEvent.title || currentEvent.summary}
            </div>
            <EventDurationBooked event={currentEvent} />
            <div>{`${t('Booked.Organizer:')} ${currentEvent.customerName || " "}`}</div>
            <div className="centered-div">
                {endNowButton}
            </div>
        </div>
    };

    renderCurrentInfoNew = (currentEvent, endNowButton) => {
        const { t } = this.props
        console.log("currentEvent : ",currentEvent)
        return <div>
            {currentEvent.welcomeMessage ? 
                <div class="event-welcomeMessage">
                    {currentEvent.welcomeMessage}
                </div>
                :
                null             
             }
            {/*<div class="event-label">
                {t('Booked.CurrentMeeting')}
            </div>*/}
            <div class="event-title" style={{fontSize:35}}>
                {currentEvent.title || currentEvent.summary}
            </div>
            <div class="event-info" style={{fontSize:35}}>
                <EventDurationBooked event={currentEvent} />
                <div>{`${t('Booked.Organizer:')} ${currentEvent.organiser && currentEvent.organiser.name || currentEvent.customerName }`}</div>  
                <div className="centered-div end-now">
                    {endNowButton}
                </div>
            </div>
        </div>
    };

    renderButtonGroup = () => {
        const { t } = this.props
        return <Grid item container
        justifyContent="center"
        alignItems="center"
        className="button-group">
            <Grid item className='button-item'>
                <Button 
                name={t('Booked.15Min')}
                className={"button-primary"}
                deactivated={true} />
            </Grid>
            <Grid item className='button-item'>
                <Button 
                name={t('Booked.30Min')}
                className={"button-primary"}
                deactivated={true} />
            </Grid>
            <Grid item className='button-item'>
                <Button 
                name={t('Booked.60Min')}
                className={"button-primary"}
                deactivated={true} />
            </Grid>
        </Grid>;
    };

    render() {
        const { t } = this.props
        const { currentEvent, onClick, onShowScheduleClick, room, waitUntil } = this.props;
        const remainingTimeMessage = isEmpty(currentEvent) ? null : this.bookedStatusSubMessage(currentEvent);
        const endNowButton = this.conditionalFinishButton(currentEvent.organiser, onClick, !isAllDayEvent(currentEvent));
        const bookedClassName = waitUntil ? 'wait-details' : 'status-details-booked';
        return <div className={bookedClassName} style={{background: 'url("/images/bg.png") no-repeat center center fixed'}}>
            <Grid container style={{height:'100vh'}}>
                <Logo />
                <Grid item xs={6}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className='left-side'
                >
                    <div className="main-head">
                        <RoomDetails roomObject={room} />
                        {this.renderBookedInfoNew(waitUntil, remainingTimeMessage)}
                        {this.renderCurrentInfoNew(currentEvent, endNowButton)}
                    </div>
                </Grid> 
                <Grid item xs={6}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className='right-side'
                >
                <Grid item style={{position:'absolute', right:'30px', top:'30px' }}> 
                    <FormControl>
                        <LanguageDropdown lang={this.props.lang} onChange={this.props.handleLanguageChange} />
                    </FormControl>
                </Grid>                   
                <Timer lang={this.props.lang}/>
                    {this.renderButtonGroup()}
                    <Grid item container direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className="button-group">
                        <Grid item className='button-item'>
                            <Button 
                            deactivated={true}
                            name={t('Booked.Organizer')} 
                            className={"button-primary"} 
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className="button-group">
                        <Grid item className='button-item'>
                            <button className="icon-button" onClick={onShowScheduleClick}>
                                <CalendarIcon height="80%" />
                            </button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>;
        /*return <div className={bookedClassName}>
            <HeaderBar onClick={onShowScheduleClick} />
            <RoomDetails roomObject={room} />
            {this.renderBookedInfo(waitUntil, remainingTimeMessage)}
            {this.renderCurrentInfo(currentEvent, endNowButton)}
        </div>; */
    }
};
export default withTranslation()(Booked)