import Calendar from "../models/Calendar";
import BackendApi from '../backendcomm/api/app2backend';

export function getCalendar(tenantId, roomId ,successCb,errorCb) {
    BackendApi.getCalendar(tenantId, roomId, (res) => {
        const room = res.room;
        const calendar = new Calendar(room.id, room.name, room.description, room.notes,room.color,room.Location, room.additionalPrice,room.minBookingTime).toUIJSON();
        successCb(calendar);
    }, (err) => {
        errorCb(err);
    })
}