export default {
    LOGIN           : "login",
    AUTH_CALL       : "me",
    REFRESH         : "refresh",
    LOGOUT          :"logout",
    ORGANIZER_LIST  : (tenantId) => `tenant/${tenantId}`,
    GET_CALENDAR    : (tenantId,roomId) => `${tenantId}/calendars/${roomId}`,
    GET_BOOKINGS    : (tenantId,deviceAccountId) => `${tenantId}/calendars/forDevice/${deviceAccountId}`,
    ADD_NEW_BOOKING : (tenantId,deviceId) => `${tenantId}/calendars/forDevice/${deviceId}`,
    FINISH_BOOKING  : (tenantId,eventId) => `${tenantId}/calendars/finishBooking/${eventId}`
}