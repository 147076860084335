import { IconButton, MenuItem, Select } from '@material-ui/core';
import { IconFlagDE, IconFlagUS } from 'material-ui-flags';
import React from 'react';
const LanguageDropdown = (props) => {
return ( <div style={{marginRight:"16px"}}
className='LangDropDown'
>
    <Select
        value={props.lang}
        onChange={props.onChange}
        disableUnderline={true}
    >
        <MenuItem value="de-DE" >
        <IconFlagDE />
        </MenuItem> 
        <MenuItem value="en-US" >
        <IconFlagUS />
        </MenuItem> 
    </Select></div>);
 }
export default LanguageDropdown;