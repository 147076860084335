import { HTTP_REQUESTS } from '../services/httpRequestService';

class BackendApi {
    login = ({ email, password }, successCallback, errorCallback) => {
        HTTP_REQUESTS.USER_SERVICE.LOGIN({ email, password }, successCallback, errorCallback);
    };

    auth = (successCallback, errorCallback) => {
        HTTP_REQUESTS.USER_SERVICE.AUTH_CALL(successCallback, errorCallback);
    };

    getCalendar = (tenantId,roomId,successCallback, errorCallback) => {
        HTTP_REQUESTS.MOBILE_SERVICE.GET_CALENDAR(tenantId, roomId,successCallback, errorCallback);
    };

    getBookings = (tenantId,deviceAccountId,successCallback, errorCallback) => {
        HTTP_REQUESTS.MOBILE_SERVICE.GET_BOOKINGS(tenantId,deviceAccountId,successCallback, errorCallback);
    };

    addNewBooking = (tenantId,deviceId,eventInfo, successCallback, errorCallback) => {
        HTTP_REQUESTS.MOBILE_SERVICE.ADD_NEW_BOOKING(tenantId,deviceId,eventInfo, successCallback, errorCallback);
    };

    finishBooking = (tenantId,eventId,eventInfo, successCallback, errorCallback) => {
        HTTP_REQUESTS.MOBILE_SERVICE.FINISH_BOOKING(tenantId,eventId,eventInfo, successCallback, errorCallback);
    };

    getOrganizerList = (tenantId, successCallback, errorCallback) => {
        HTTP_REQUESTS.USER_SERVICE.ORGANIZER_LIST(tenantId, successCallback, errorCallback);
    }

    refresh = (successCallback, errorCallback) => {
        HTTP_REQUESTS.USER_SERVICE.REFRESH(successCallback, errorCallback);
    };
    
    logout = (successCallback, errorCallback) => {
        HTTP_REQUESTS.USER_SERVICE.LOGOUT(successCallback, errorCallback);
    };
};

export default new BackendApi();