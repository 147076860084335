import RequestPaths from "../paths/RequestPaths";
import RequestURLs from "../paths/RequestURLs";

export const REQUEST_PATHS_FOR = {
    USERS_SERVICE: {
        LOGIN: () => {
            return `${RequestURLs.USERS_SERVER_BASE_URL}${RequestURLs.USERS_DEVICE_POSIX}${RequestPaths.LOGIN}`;
        },
        AUTH_CALL: () => {
            return `${RequestURLs.USERS_SERVER_BASE_URL}${RequestURLs.USERS_DEVICE_POSIX}${RequestPaths.AUTH_CALL}`;
        },
        ORGANIZER_LIST: (tenantId) => {
            return `${RequestURLs.USERS_SERVER_BASE_URL}${RequestURLs.USERS_API_POSIX}${RequestPaths.ORGANIZER_LIST(tenantId)}`;
        },
        REFRESH: () => {
            return `${RequestURLs.USERS_SERVER_BASE_URL}${RequestURLs.USERS_DEVICE_POSIX}${RequestPaths.REFRESH}`;
        },
        LOGOUT: () => {
            return `${RequestURLs.USERS_SERVER_BASE_URL}${RequestURLs.USERS_DEVICE_POSIX}${RequestPaths.LOGOUT}`;
        },
    },
    MOBILE_SERVICE: {
        GET_CALENDAR: (tenantId,roomId) => {
            return `${RequestURLs.BOOKING_SERVER_BASE_URL}${RequestURLs.USERS_TENANT_POSIX}${RequestPaths.GET_CALENDAR(tenantId,roomId)}`;
        },
        GET_BOOKINGS: (tenantId,deviceAccountId) => {
            return `${RequestURLs.BOOKING_SERVER_BASE_URL}${RequestURLs.USERS_TENANT_POSIX}${RequestPaths.GET_BOOKINGS(tenantId,deviceAccountId)}`;
        },
        ADD_NEW_BOOKING: (tenantId,deviceId) => {
            return `${RequestURLs.BOOKING_SERVER_BASE_URL}${RequestURLs.USERS_TENANT_POSIX}${RequestPaths.ADD_NEW_BOOKING(tenantId,deviceId)}`;
        },
        FINISH_BOOKING: (tenantId,eventId) => {
            return `${RequestURLs.BOOKING_SERVER_BASE_URL}${RequestURLs.USERS_TENANT_POSIX}${RequestPaths.FINISH_BOOKING(tenantId,eventId)}`;
        }
    }
};