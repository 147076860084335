import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
const Languages = ['de-DE','en-US'];
const currentLang = localStorage.getItem('language');

i18n
  .use(Backend)
  //.use(LanguageDetector)
  .use(initReactI18next)

  .init({
    lng: currentLang,
    debug: true,
    fallbackLng: "de-DE",
    react: { 
      useSuspense: false
    },
    whitelist:Languages,
    interpolation: {
      escapeValue: false,
    }
  });


export default i18n;