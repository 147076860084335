import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import BackendApi from '../../backendcomm/api/app2backend';
import '../../styles/login.css';
import navigate from '../../helpers/navigate';
import Button from '../Button';
import { withTranslation } from 'react-i18next';
import { FormControl, Grid } from '@material-ui/core';
import LanguageDropdown from '../languageDropDown'

class UserLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            password: null,
            lang : (localStorage.getItem('language')) ? localStorage.getItem('language') : 'de-DE'
        };
    }

    componentDidMount() {
        console.log("user login component didmount")
        const {t,i18n} = this.props
        i18n.changeLanguage(this.state.lang); localStorage.setItem('language',this.state.lang); 
    }

    handleChange = (data, stateKey) => {
        this.setState({ [stateKey]: data.value });
    }

    handleLogin = () => {
        const { email, password } = this.state;
        BackendApi.login({ email, password }, (res) => {
            if (window.ReactNativeWebView) { // login from webview
                window.ReactNativeWebView.postMessage(JSON.stringify(res));
                navigate(`/status`);
            } else {
                navigate(`/status`);
                window.location.reload();
            }
        }, (err) => {
            this.createErrorNotification();
        });

    };

    createErrorNotification = () => {
        this.setState({ error: true }, () => {
            setTimeout(() => {
                this.setState({ error: false });
            }, 2000);
        });
    };

    isNullOrUndefined = (value) => {
        return value === "" || value === null || value === undefined;
    };

    checkDeactive = () => {
        return this.isNullOrUndefined(this.state.email) || this.isNullOrUndefined(this.state.password) || this.state.error;
    };

    handleLanguageChange = (event) => {
        const targetLang = event.target.value
        this.setState({lang : targetLang})
        const {i18n} = this.props
        i18n.changeLanguage(targetLang); localStorage.setItem('language',targetLang); 
      };

    render() {
        const { t } = this.props
        return <div className="login-form">
            <h3>{this.state.error ? t('UserLogin.ValidationError') : null}</h3>
            <Grid item style={{position:'absolute', right:'30px', top:'30px' }}> 
                <FormControl>
                    <LanguageDropdown lang={this.state.lang} onChange={this.handleLanguageChange} />
                </FormControl>
            </Grid>     
            <h1>{t('UserLogin.QuickBooking') }</h1>
            <div className="login-field">
                <TextField label={t('UserLogin.Email')} variant="filled" onChange={(e) => this.handleChange(e.target, "email")} />
            </div>
            <div className="login-field">
                <TextField label={t('UserLogin.Password')} variant="filled" type="password" onChange={(e) => this.handleChange(e.target, "password")} />
            </div>
            <div className="login-field">
                <Button name={t('UserLogin.Login')} handleClick={this.handleLogin} className={"custom-login-button"} deactivated={this.checkDeactive()} color="#41A3E8" />
            </div>
        </div>
    }
};

export default withTranslation()(UserLogin)