import React, { Component } from 'react';
import '../styles/roomdetail.css';

export default class RoomDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        let roomObject = this.props.roomObject;
        return <div class="room-title">{roomObject && roomObject["name"] ? roomObject.name : "Meeting Room"}</div>;
    }
};